











































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { mapState } from 'vuex'
import { ApiResponse, Pagination, Document } from '@/interfaces'
import { DatePicker } from 'element-ui'

interface DropdownAction {
  action: string
}

export default Vue.extend({
  components: { Nav },
  data() {
    return {
      busy: false,
      sales: [], // as Document[],
      search: '',
      page: '1',
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      pageSize: 1,
      orderBy: null as null | string,
      order: null as null | string,
      structureId: null as null | string,
      structures: [] as Record<string, string | null>[],
      stats: '',
      addons: 0,
      datePeriod: [],
      datePeriodOptions: {
        shortcuts: [
          {
            text: 'Mois dernier',
            onClick(picker: DatePicker) {
              const start = new Date()
              const startMonth = start.getMonth() - 1
              start.setDate(1)
              start.setMonth(startMonth)
              const end = new Date(start.getFullYear(), startMonth + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Mois en cours',
            onClick(picker: DatePicker) {
              const start = new Date()
              start.setDate(1)
              const end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.structures = newVal.client.structures
      this.addons = newVal.client?.addons ?? 0
    },
  },
  mounted() {
    if (this.user) {
      this.structures = this.user.client.structures
      this.addons = this.user.client?.addons ?? 0
    }

    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.orderby) {
      this.orderBy = this.$route.query.orderby as string
    }

    if (this.$route.query.order) {
      this.order = this.$route.query.order as string
    }

    if (this.$route.query.structure) {
      this.structureId = this.$route.query.structure as string
    }

    if (this.$route.query.datePeriod) {
      this.datePeriod = this.$route.query.datePeriod as []
    }
    this.getSales()
  },
  methods: {
    updateRoute(resetPage = false) {
      if (resetPage === true) {
        this.page = '1'
      }

      // Setting query URL
      const query = {
        search: this.search,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
        structure: this.structureId,
        datePeriod: this.datePeriod,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    getSales() {
      if (!this.busy) {
        this.busy = true

        const loading = this.$loading({
          target: '#salesBookTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get('/document/sales/list', {
            params: {
              search: this.search,
              page: this.page,
              orderby: this.orderBy,
              order: this.order,
              structure: this.structureId,
              saleDate: this.datePeriod,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.sales = apiResponse.data.documents
            this.stats = apiResponse.data.stats

            const pagination = apiResponse.data.pagination as Pagination

            this.activePage = pagination.current
            this.itemsTotal = pagination.totalCount
            this.numPages = pagination.pageCount
            this.pageSize = pagination.numItemsPerPage
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    getStructureName(document: Document) {
      return document.structure?.name ?? '-'
    },
    changePage(page: string) {
      this.page = page
      this.updateRoute()
    },
    openDocument(document: Document) {
      let url = ''

      if (document.format == 'pdf') {
        url = `${process.env.VUE_APP_API_URL}/document/${document.accessToken}/download?contentd=inline`
      } else {
        url = `${process.env.VUE_APP_API_URL}/document/${document.accessToken}/download`
      }

      window.open(url, '_blank')
    },
    filterStructure(id: string) {
      this.structureId = id
      this.updateRoute(true)
    },
    handleItemCommand(command: DropdownAction) {
      // export items
      if (command.action == 'pdf') {
        this.exportpdf()
      }
    },
    exportpdf() {
      // Get data for statictics
      this.busy = true
      this.$api
        .get('/document/sales/list', {
          params: {
            search: this.search,
            page: this.page,
            orderby: this.orderBy,
            order: this.order,
            structure: this.structureId,
            saleDate: this.datePeriod,
            format: 'pdf',
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once?contentd=inline`
          window.open(documentUrl, '_blank')
        })
        .finally(() => {
          this.busy = false
        })
    },
  },
})
